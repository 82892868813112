<template>
  <div
    class="container px-1 cotizacion-container"
    :class="{ disabled: !modelPrice }"
  >
    <v-card rounded elevation="0" class="mx-auto cotizacion-cart">
      <v-fade-transition>
        <v-overlay v-if="cargando || loadingCarrito || loadingDesign" absolute>
          <v-row align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
          <v-row v-if="creando">
            <span>
              {{ $t("general.Esta operación puede tardar unos minutos") }}
            </span>
          </v-row>
        </v-overlay>
      </v-fade-transition>

      <v-card-text>
        <alerta
          :dialog="alertar"
          :titulo="$t('confirm.Sin zonas')"
          :boton-cerrar="$t('confirm.Cerrar')"
          :cuerpo="$t('confirm.Seleccione zonas')"
          @cerrar="cierraAlerta"
        />
        <div class="d-flex align-end justify-space-between">
          <div>
            <span class="litle-head-title">{{ $t("general.Cantidad") }}</span>
            <p class="head-title">
              {{ totalUnidades }} {{ $t("general.unidades") }}
            </p>
          </div>
          <CantidadExacta
            v-if="hasCantidadesExactas"
            v-model="cantidadExacta"
            :disabled="cargando || loadingCarrito || loadingDesign"
          ></CantidadExacta>
        </div>

        <span v-if="isPreciosProveedoresActive" class="d-flex">
          <v-checkbox
            v-model="checkProveedor"
            :disabled="cargando"
            :label="$t('configuracion.EsProveedor')"
            color="primary darken-2 white--text"
            class="mt-1 pt-1"
          ></v-checkbox>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon class="mt-1">mdi-information</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("configuracion.InfoEsProveedor") }}</span>
          </v-tooltip>
        </span>

        <!-- <br /> -->
        <!-- <p>{{ $t("general.Resumen") }}</p> -->
        <div
          class="text--primary scrollable-list"
          :class="{ youReprocesosList: isYouReprocesos }"
        >
          <v-list dense expand class="pa-0">
            <v-list-group
              v-for="(reproceso, key) in carrito.reprocesos"
              :key="key"
              color="primary darken-2"
              class="cotizacion-resumen-reprocesos"
              no-action
              :riple="true" 
            >
              <template #activator>
                <v-list-item-content class="pa-0">
                  <v-list-item-title class="expandible-cotizacion-tecnica">
                    <span class="header-reprocesos-zone">
                      {{ formatHeader(reproceso) }}
                    </span>
                    <span class="tecnica-reprocesos-zone">
                      <template v-if="!reproceso.isManipulado">/</template>
                      {{ translateTecnica(reproceso.tecnica) }} :
                    </span>
                    <b>
                      <template v-if="reproceso.total != 0">
                        &nbsp;{{ reproceso.total | filtrarPreciosDosDecimales
                        }}{{ currencySymbol }}
                      </template>
                      <template v-else-if="reproceso.error">
                        &nbsp;
                        <span class="error">
                          {{ $t("alert.genericTitleError") }}
                        </span>
                      </template>
                      <template v-else>
                        &nbsp;{{ $t("general.Incluido") }}
                      </template>
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(linea, key2) in reproceso.lineas"
                :key="key2"
                class="cotizacion-resumen-linea"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    <span class="line-name">
                      {{ linea.nombre }} <br> <span class="line-quantity">{{ linea.id }}</span>
                    </span>

                    <span v-if="linea.total != 0" class="line-quantity">
                      <span>{{ linea.cantidad }}</span>
                      <span
                        v-if="linea.multiplicador && linea.multiplicador > 1"
                      >
                        x{{ linea.multiplicador }}
                      </span>
                      x{{ linea.precio }}{{ currencySymbol }}
                      <span class="line-price">
                        = {{ linea.total }}{{ currencySymbol }}
                      </span>
                    </span>
                    <template v-else>
                      {{ $t("general.IncluidoDescripcion") }}
                    </template>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>

        <div class="mt-6">
          <div class="d-flex flex-column">
            <span class="litle-head-title">{{ $t("general.Total") }}</span>
            <v-divider class="mt-3" color="black" />
            <div class="d-flex flex-row mt-3">
              <span class="total-head">
                <b>
                  {{ precioPrendasReprocesos | filtrarPreciosDosDecimales
                  }}{{ currencySymbol }}
                </b>
              </span>
            </div>
            <div class="mt-3 grouped-total-prices d-flex flex-column">
              <span>
                {{ $t("configuracion.Prendas") }}:
                {{ preciosPrendas | filtrarPreciosDosDecimales }}{{ currencySymbol }}
              </span>
              <span>
                {{ $t("configuracion.Trabajos") }}:
                <template v-if="preciosReprocesos != 0 || totalUnidades == 0">
                  {{ preciosReprocesos | filtrarPreciosDosDecimales }}{{ currencySymbol }}
                </template>
                <template v-else>
                  {{ $t("general.Incluido") }}
                </template>
              </span>
              <span>
                {{ $t("configuracion.PrecioUnitario") }}: {{ precioUnitario | filtrarPrecios
                }}{{ currencySymbol }}
              </span>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <div class="w-100">
          <!-- <div v-if="totalUnidades > 0 && !hasErrorInCart" class="pb-7">
              <presupuesto v-if="!isDisableCustomPrices && !isYouReprocesos" />
              <pdf v-else cotizador-btn :mis-precios="defaultPdfConfig" />
            </div> -->
          <p class="textoCondiciones">
            <small
              v-if="addShoppingCartActive && precioPrendasReprocesos > 999999"
            >
              {{ $t("alert.alertMaxPrice") }}
            </small>
            <small v-if="addShoppingCartActive && totalUnidades <= 0">
              {{ $t("alert.alertSelectProduct") }}
            </small>
          </p>
          <v-alert
            :value="hasTecnicaNoCalcular"
            dense
            type="warning"
            transition="scale-transition"
          >
            {{ $t("general.isTecnicaNoCalcular") }}
          </v-alert>
          <div
            v-if="
              addShoppingCartActive &&
              precioPrendasReprocesos < 999999 &&
              totalUnidades > 0 &&
              numReprocesos > 0 &&
              !hasErrorInCart
            "
            class="w-100"
            @click="isYouReprocesos ? (youReprocesosDialog = true) : confirm()"
          >
            <CotizadorBtn content-class="w-100" success>
              {{ isYouReprocesos ? $t("cart.sendConfirmYouReprocesos")  : $t("cart.addToBudget") }}
            </CotizadorBtn>
          </div>
        </div>
        <p v-if="hasCanonDigital || isFireResistant" class="textoCondiciones">
          <!-- {{ $t("general.Aviso") }}: -->
          <br />
          <small v-if="hasCanonDigital" class="canonDigital">
            {{ $t("general.avisoCanonDigital") }}
            <br />
          </small>
          <small v-else-if="isFireResistant">
            {{ $t("general.avisoPrendasIgnifugas") }}
            <br />
          </small>
          <!-- <small>
              {{ $t("general.texto-condiciones") }}
              <br />
              {{ $t("general.texto-condiciones-aux") }}
              <br />
            </small> -->
        </p>
      </v-card-actions>
    </v-card>
    <you-reprocesos-confirm-dlg
      v-if="isYouReprocesos"
      :dialog.sync="youReprocesosDialog"
      @confirm="confirm"
    ></you-reprocesos-confirm-dlg>
    <span class="litle-head-title">
      <b>*{{ $t("general.preciosOrientativos") }}</b>
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <u v-bind="attrs" v-on="on">{{ $t("general.showMore") }}</u>
        </template>
        <span>{{ $t("general.texto-condiciones") }}</span>
      </v-tooltip>
    </span>
    <v-dialog
      ref="dialogTools"
      v-model="dialogAddedToCart"
      persistent
      transition="dialog-bottom-transition"
      max-width="450"
    >
      <v-card class="dialog-restart" color="bannerBackground">
        <v-card-title
          class="d-flex flex-column justify-center align-center"
        >
          {{ $t("general.PedidoFinalizado") }}
        </v-card-title>
        <v-card-text class="d-flex flex-column justify-center mt-2">
          <template v-if="isYouReprocesos">
            <span>{{$t("general.pedidoYouReprocesosFinalizado")}}</span>
          </template>
          <template v-else>
            <span>{{ $t("general.ReprocesoActualAdded") }}</span>
            <span class="mt-3">
              {{ $t("general.BorramosReprocesoActual") }}
            </span>
          </template>
        </v-card-text>
        <v-card-actions>
          <template v-if="isYouReprocesos">
              <v-btn color="darken-1"  @click="reiniciar()">
            {{ $t("general.Aceptar") }}
          </v-btn>
            </template>

          <template v-else>
            <v-btn color="darken-1"  @click="reiniciar()">
            {{ $t("general.Reiniciar") }}
          </v-btn>
          <v-btn color="darken-1"  @click="dialogAddedToCart = false">
            {{ $t("general.Mantener") }}
          </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { tryGenerateMergeZones } from "@/utils/fabricUtils.js";
import { mapActions, mapGetters } from "vuex";
import CantidadExacta from "../basic/CantidadExacta.vue";
import CotizadorBtn from "../basic/CotizadorBtn.vue";
// import cartElement from "../basic/cartElement.vue";
import alerta from "../modales/alert.vue";
// import pdf from "../presupuestado/generadorPDFPresupuesto.vue";
// import presupuesto from "../presupuestado/presupuesto.vue";
import youReprocesosConfirmDlg from "../modales/youReprocesosConfirmDlg.vue";

export default {
  name: "Cotizacion",
  components: {
    // presupuesto,
    alerta,
    CotizadorBtn,
    CantidadExacta,
    // pdf,
    // cartElement
    youReprocesosConfirmDlg
  },
  props: {
    cantidad: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      cargando: false,
      creando: false,
      alertar: false,
      cantidadExacta: false,
      checkProveedor: false,
      dialogAddedToCart: false,
      loadingClientCart: false,
      youReprocesosDialog: false,
    };
  },
  computed: {
    ...mapGetters("carrito", [
      "carrito",
      "preciosPrendas",
      "preciosReprocesos",
      "sobrecosteCantidadExacta",
      "clientCart",
      "countClientCart",
      "loadingCarrito",
      "loadingDesign",
      "totalClientCart",
      "precioUnitario",
      "colorCode",
      "totalUnidades",
      "savedDesign",
      "filteredListDesignInCart",
      "numDesignForEachPosition",
      "numReprocesos",
      "numDesignForEachPreviewRule",
      "previewGlobalDesign",
      "hasErrorInCart",
      "hasTecnicaNoCalcular"
    ]),
    ...mapGetters("modelo", [
      "codigosModelos",
      "productos",
      "modelPrice",
      "tecnicasCantidadExacta",
      "incrementoPrendasExactas",
      "currencySymbol",
      "hasCanonDigital",
      "modelInfoFromCatalog",
      "zonas",
      "isFireResistant",
      "newComputedImgUrl"
    ]),
    ...mapGetters("config", [
      "isDisableCustomPrices",
      "defaultPdfConfig",
      "isProveedor",
      "isPreciosProveedoresActive",
      "translateTecnica",
      "key",
      "comercialInfo",
      "comercialLoginAvalible",
      "clientCartAvalible",
      "colorSelected",
      "isYouReprocesos",
      "youReprocesosConfig"
    ]),
    hasCantidadesExactas() {
      return (
        this.incrementoPrendasExactas &&
        this.carrito.reprocesos.some(r =>
          this.tecnicasCantidadExacta.includes(r.tecnica)
        )
      );
    },
    addShoppingCartActive() {
      return (
        process.env.VUE_APP_COMPRA_ACTIVA &&
        process.env.VUE_APP_COMPRA_ACTIVA == "true" &&
        (!this.isYouReprocesos ||
          (this.isYouReprocesos && this.youReprocesosConfig.activeSendBudget))
      );
    },
    precioPrendasReprocesos() {
      return parseFloat(this.preciosPrendas + this.preciosReprocesos).toFixed(
        2
      );
    }
  },
  watch: {
    dialogAddedToCart() {
      this.$nextTick(() => {
        this.$refs.dialogTools.showScroll();
      });
    },
    cantidadExacta(newVal, oldVal) {
      if (newVal != oldVal) {
        newVal ? this.CalcularCantidadExacta() : this.EliminarCantidadExacta();
      }
    },
    hasCantidadesExactas(newVal, oldVal) {
      if (newVal != oldVal && this.sobrecosteCantidadExacta > 0 && !newVal) {
        this.EliminarCantidadExacta();
      } else {
        if (
          newVal &&
          this.cantidadExacta &&
          this.sobrecosteCantidadExacta <= 0
        ) {
          this.CalcularCantidadExacta();
        }
      }
    },
    checkProveedor(newVal) {
      this.setProveedorCheck(newVal ?? false);
    },
    precioPrendasReprocesos(newVal) {
      if (newVal > 999999) {
        this.addAlert({
          code: "maxPrice",
          cuerpo: "alertMaxPrice"
        });
      }
    },
    cargando() {
      this.updateTimeoutHeight();
    },
    loadingClientCart() {
      this.updateTimeoutHeight();
    }
  },
  async beforeMount() {
    if (this.clientCartAvalible && !this.comercialLoginAvalible) {
      this.fetchClientCart();
    }
  },
  methods: {
    ...mapActions("carrito", [
      "fetchCarrito",
      "confirmPedido",
      "CalcularCantidadExacta",
      "EliminarCantidadExacta",
      "fetchClientCart",
      "fetchComercialCart",
      "deleteCompleteClientCart",
      "confirmClientCart",
      "cleanAllDesign"
    ]),
    ...mapActions("config", [
      "setProveedorCheck",
      "setResetCalculadora",
      "updateHeightFrame"
    ]),
    ...mapActions("modelo", ["addAlert"]),
    updateTimeoutHeight() {
      setTimeout(() => {
        this.updateHeightFrame();
      }, 100);
    },
    deleteCart() {
      this.loadingClientCart = true;
      this.deleteCompleteClientCart().finally(() => {
        this.loadingClientCart = false;
      });
    },
    confirmAll() {
      this.loadingClientCart = true;
      this.confirmClientCart().finally(() => {
        this.loadingClientCart = false;
      });
    },
    setLoadingClientCart(event) {
      this.loadingClientCart = event;
    },
    cierraAlerta() {
      this.alertar = false;
    },

    formatZona(value) {
      return value ? value.toUpperCase().replace(/_/g, " ") : "";
    },
    reiniciar() {
      this.$emit("reload");
      this.setResetCalculadora(true);
      this.cleanAllDesign();
      this.dialogAddedToCart = false;
    },
    formatHeader(reproceso) {
      if (reproceso.tecnica == "Manipulados" || reproceso.isManipulado)
        return this.$t("configuracion.ReprocesosGenerales.Manipulados");

      if (reproceso.tecnica == "Etiquetas" || reproceso.isEtiqueta)
        return this.$t("configuracion.ReprocesosGenerales.Etiquetas");

      if (reproceso.isExtraCantidadExacta)
        return this.$t("general.CantidadExacta");

      return this.$te("Zonas." + this.formatZona(reproceso.zona)) &&
        !reproceso.isExtraCantidadExacta
        ? `${this.$t("Zonas." + this.formatZona(reproceso.zona))}`
        : "";
    },
    async confirm(youReprocesosExtraInfo) {
      this.cargando = true;
      this.creando = true;
      if (this.carrito.reprocesos.length <= 0) {
        this.alertar = true;
        this.cargando = false;
        this.creando = false;
        return;
      }

      // Añadimos toda la información que debemos guardar del pedido
      var objetoPedido = new Object();

      objetoPedido.Productos = [];
      this.carrito.productos.forEach(element => {
        var obj = new Object();
        obj.CODE = element.id;
        // let name = this.modelPrice?.productTranslation?.find(
        //   p => p.code == obj.CODE
        // )?.translate;
        let name =  this.$i18n.t(`PRODUCTS.${obj.CODE}`)
        obj.NAME =
          name ??
          this.$i18n.t(`MODELS.${this.carrito.modelo}.NAME`) +
            "+" +
            this.$i18n.t(`SIZES.${element.size}`) +
            "+" +
            this.$i18n.t(`COLORS.${element.color}`);
        obj.quantity = element.cantidad;
        obj.MODEL = this.carrito.modelo;

        objetoPedido.Productos.push(obj);
      });

      objetoPedido.Reprocesos = [];
      this.carrito.reprocesos.forEach(element => {
        var obj = new Object();
        obj.tecnica = element.isExtraCantidadExacta
          ? "Cantidad Exacta"
          : element.tecnica;
        obj.zona = element.zona;

        if (!element.isExtraCantidadExacta) {
          if (
            !element.tecnica.includes("Etiquetas") &&
            !element.tecnica.includes("Manipulacion") &&
            !element.tecnica.includes("Planchado")
          ) {
            obj.temporalId = element.id;
          } else {
            obj.temporalId = -1;
          }
        }
        // obj.parametros.pedidorepetido = false;
        obj.params = element.params;

        objetoPedido.Reprocesos.push(obj);
      });

      // Información del diseño aplicado
      var objDesign = new Object();
      objDesign.listDesign = [];
      this.filteredListDesignInCart.forEach(design => {
        let actualDesign = {};

        actualDesign.temporalId = design.id;
        actualDesign.zone = design.zona;
        actualDesign.posicion = design.posicion;

        if (design.canvasJson) {
          // Si solo tenemos una imagen, creamos el array, y guardamos la única imagen, sino, guardamos el array de imágenes
          actualDesign.design = Array.isArray(design.design)
            ? design.design.map((design, index) => {
                return {
                  index,
                  design,
                  originalFile:
                    design.design != design.originalFile
                      ? design.originalFile
                      : null
                };
              })
            : [
                {
                  index: 0,
                  design: design.design,
                  originalFile:
                    design.design != design.originalFile
                      ? design.originalFile
                      : null
                }
              ];

          // Debemos parametrizar el json, para luego sustituir la imagen en base64 por su url cuando se haya subido la(s) imagen(es)
          let resultJson = JSON.stringify(design.canvasJson);
          actualDesign.design.forEach(design => {
            // SI es una url no debemos hacer nada
            try {
              new URL(design.design);
            } catch (e) {
              resultJson = resultJson.replace(
                design.design,
                `[IMAGE_REPROCESO_${actualDesign.temporalId}_${design.index}]`
              );
            }
          });
          actualDesign.json = resultJson;

          // Guardamos el svg como preview
          actualDesign.preview = design.svg;
        }

        // Guardamos las observaciones que se han puesto en la zona
        actualDesign.observation = design.observaciones;

        // Guardamos la personalización
        objDesign.listDesign.push(actualDesign);
      });

      objDesign.preview = await tryGenerateMergeZones(
        this.numDesignForEachPosition,
        this.numDesignForEachPreviewRule,
        this.filteredListDesignInCart
      );

      if (!objDesign.preview && objDesign.listDesign.length > 0) {
        objDesign.preview = objDesign.listDesign[0].preview;
      }

      if (
        this.previewGlobalDesign.hasZoneDesign ||
        this.previewGlobalDesign.hasPreviewRule
      ) {
        objDesign.zonaPreview = this.previewGlobalDesign.zones[0].Zona;
        objDesign.previewRule = this.previewGlobalDesign.zones[0].PreviewRule;
      }
      objDesign.colorPreview = this.colorSelected;

      let body = {
        order: objetoPedido,
        NewDesignDataForm: JSON.stringify(objDesign)
      };
      if (this.isYouReprocesos && youReprocesosExtraInfo) {
        
        body.YouReprocesosInfo = {
          Email: youReprocesosExtraInfo.mail,
          Name: youReprocesosExtraInfo.name,
          Address: youReprocesosExtraInfo.address?.street,
          Cp: youReprocesosExtraInfo.address?.cp,
          State: youReprocesosExtraInfo.address?.state,
          Country: youReprocesosExtraInfo.address?.country,
          City: youReprocesosExtraInfo.address?.city,
          Phone: youReprocesosExtraInfo.address?.phoneNumber,
          Comments: youReprocesosExtraInfo.observaciones,
          Reference: youReprocesosExtraInfo.referencia
        };
      }

      await this.confirmPedido({
        body,
        key: this.key
      })
        .then(() => {
          this.dialogAddedToCart = true;
          parent.postMessage({ type: "reloadCart" }, "*");
          if (this.clientCartAvalible) {
            this.fetchClientCart();
          }

          if (this.comercialLoginAvalible) {
            this.fetchComercialCart();
          }
        })
        .finally(() => {
          this.creando = false;
          this.cargando = false;
        })
        .catch(error => {
          if (!error?.response?.data) {
            console.error(error);
            return;
          }
          const err = error.response.data;
          const messageAlert = `(${err.ProductCode}) ${this.$root.$i18n.t(
            "errores." + err.IdError
          )}`;
          alert(messageAlert);
        });
    },
    imgUrl: function (zonaSelect) {
      return this.newComputedImgUrl(zonaSelect, this.colorCode);
    }
  },
  created() {
    this.checkProveedor = this.isProveedor;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.v-card.dialog-restart {
    color: var(--v-colorTextBanner-base) !important;
  padding: 20px;
    .v-card__title {
    text-transform: uppercase;
    text-align: center;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .v-card__text {
    color: inherit !important;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding: 0px;
  }

  .v-card__actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    .v-btn__content {
    color: var(--Color-Black, #121212);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
  }
  }
  


.v-ripple__container {
  display: none;
}

.cotizacion-container {
  padding: 0px;
  // border: .5px solid #5a5a5a;
  .litle-head-title {
    color: var(--Color-Grey-600, #787878);
    font-family: $heading-font-family;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .head-title {
    color: var(--Color-Black, #121212);
    font-family: $heading-font-family;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .total-head {
    color: var(--Color-Black, #121212);
    font-family: $heading-font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .grouped-total-prices {
    color: var(--Color-Grey-600, #787878);
    font-family: $heading-font-family;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &.disabled {
    opacity: 0.4;
  }

  .cotizacion-cart {
    min-width: 300px;
    max-width: 100%;
    border: 1px solid var(--Color-Grey-400, #787878) !important;
    border-radius: 0px;
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .scrollable-list {
      max-height: 387px;
      min-height: 387px;
      // height: 100px;
      overflow-y: scroll;

      &.youReprocesosList {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          max-height: 387px;
          background-image: var(--v-youReprocesos-logo-url, "");
          background-position: center;
          opacity: 0.3;
          // z-index: -1;
        }

        .v-list {
          background-color: rgba(255, 255, 255, 0.6) !important;

          .cotizacion-resumen-reprocesos .v-list-group__header {
            background-color: inherit;
          }
        }
      }
    }
  }
}

.cotizacion-resumen-reprocesos {
   background-color: inherit;

  .v-list-group__items {
    padding: 2px 0;
  }

  .v-icon__svg {
    color: rgba(0, 0, 0, 0.54);
  }
}

.cotizacion-resumen-linea {
  background-color: rgba(255, 255, 255, 0.6);
  min-height: 20px !important;
  .v-list-item__content {
    padding: 0px !important;
  }

  color: var(--Color-Grey-600, #787878);
  font-family: $heading-font-family;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;

  .text-wrap {
    display: flex;
    justify-content: space-between;

    .line-name {
      text-transform: capitalize;
      margin-right: 12px;
      max-width: 240px;
    }

    .line-quantity {
      font-size: 10px;
      font-weight: 400;
       .line-price {
        font-weight: 600;
      }
    }
  }
}

.textoCondiciones {
  font-size: 14px !important;
  color: rgb(192, 44, 44);

  .canonDigital {
    // text-decoration: underline;
    font-size: 16px !important;
  }
}
.textoTituloCondiciones {
  font-size: 2.125rem !important;
}

.clientCartExpansionPanel {
  font-size: 21px !important;
}

.expandible-cotizacion-tecnica {
  color: var(--Color-Grey-600, #787878);
  font-family: $heading-font-family;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  .header-reprocesos-zone {
    text-transform: uppercase;
  }

  .tecnica-reprocesos-zone {
    font-size: 10px;
  }

  b {
    color: var(--Color-Black, #121212);
    font-weight: 500;
  }
}
</style>
