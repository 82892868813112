<template>
  <v-card
    v-if="hasKeyActive"
    ref="searchModelDialog"
    class="searchModelDialog"
    color="primary"
    :loading="loadingSearch"
  >
    <v-text-field
      v-model="searchText"
      class="searchInputModel"
      :class="{ listActive, animated: !modelPrice && !searchText }"
      :label="$t('general.Selecciona un modelo')"
      prepend-inner-icon="$fi-search"
      solo
      hide-details
      :color="color"
      @input="searchDebounce"
      @blur="hideList"
      @focus="showList"
    ></v-text-field>
    <v-list v-if="listActive" class="listSerachModel" subheader @mousedown="itemMouseDown">
      <v-list-item v-for="modelo in searchResult" :key="modelo.code" class="item-list">
        <v-list-item-avatar>
          <v-img :src="modelo.imagemodelurl"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <div class="d-flex align-center">
            <v-list-item-title>
              <div v-if="modelo" class="text-wrap" v-html="getSearchItemText(modelo)"></div>
            </v-list-item-title>
            <cotizador-btn
              :on="{
                click: () => {
                  loadModel(modelo.code);
                }
              }"
            >
              {{ $t("general.Seleccionar") }}
            </cotizador-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

import CotizadorBtn from "@/components/basic/CotizadorBtn.vue";
export default {
  components: {
    CotizadorBtn
  },
  props: {
    color: {
      type: String,
      default: "bannerBackground"
    }
  },
  data() {
    return {
      searchText: "",
      searchResult: [],
      loadingSearch: false,
      cancelationToken: null,
      showListActive: false,
      clickedInside: false
    };
  },
  computed: {
    ...mapGetters("modelo", ["codigosModelos", "modelPrice"]),
    ...mapGetters("config", [
      "actualLanguageRegion",
      "key",
      "youReprocesosSession"
    ]),
    // Indica si tenemos resultados o no
    listActive() {
      return this.searchResult && this.searchResult.length > 0 && this.showListActive;
    },
    hasKeyActive() {
      return this.key || this.youReprocesosSession;
    }
  },
  watch: {
    hasKeyActive() {
      this.searchText = "";
      let modeloId = this.$route.query.modelo;
      if (modeloId) this.loadModel(modeloId);
    },
    loadingSearch(newVal, oldVal)
    {
      if(newVal != oldVal)
      {
        this.$emit('loading-modal')
      }
    }
  },
  async mounted() {
    this.searchText = "";
    let modeloId = this.$route.query.modelo;
    if (modeloId) await this.loadModel(modeloId);
    else {
      if(!this.modelPrice?.catalog)
        this.$emit('empty-model')
    }

    document.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    ...mapActions("modelo", ["getModelAndPricesFromExtranet"]),
    // Función que se ejecuta al escribir un modelo. Lanza un debounce de medio segundo
    async searchDebounce() {
      this.searchResult = null;
      this.loadingSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.search();
        this.loadingSearch = false;
      }, 500);
    },

    // Funcón que realiza la búsqueda de modelos
    async search() {
      if (!this.searchText) {
        this.searchResult = [];
        return;
      }
      let lang = this.actualLanguageRegion;
      let params = {
        searchQuery: this.searchText,
        Language: lang,
        FilterShop: "STA,ROL,ROLSTA",
        OrderBy: "NOORDER",
        PageSize: "100"
      };

      const CancelToken = axios.CancelToken;

      if (this.cancelationToken) {
        this.cancelationToken.cancel("Cancelamos petición previa");
        this.cancelationToken = null;
      }

      this.cancelationToken = CancelToken.source();

      await axios
        .get(`${process.env.VUE_APP_API_SEARCH_ENGINE}/Search/query`, {
          params: params,
          cancelToken: this.cancelationToken.token
        })
        .then(response => {
          if (response.data.Items) {
            let regExp = new RegExp(params.searchQuery, "i");
            this.searchResult = response.data.Items.filter(
              item => this.codigosModelos.find(c => c == item.code) != null
            ).sort((a, b) => {
              return (
                b.objectID?.match(regExp)?.[0]?.length ??
                0 - a.objectID?.match(regExp)?.[0]?.length ??
                0
              );
            });
          }

          this.cancelationToken.token = null;
        })
        .catch(error => {
          console.error(error);
        });
    },

    // FUnción que devuel ve los resultados de la busqueda
    getSearchItemText(model) {
      let result =
        model.familiename + " " + model.name + " (" + model.code + ")";
      let textoToFind = this.searchText.toUpperCase();
      // Create array from searchText splitting by space character
      let searchTextArray = textoToFind.split(" ");
      // Loop through each word in searchTextArray
      for (let i = 0; i < searchTextArray.length; i++) {
        result = result.replace(
          searchTextArray[i],
          "<b>" + searchTextArray[i] + "</b>"
        );
      }
      return result;
    },

    // Función para cargar un modelo en la calculadora
    async loadModel(modelCode) {
      if (!this.hasKeyActive) return;
      this.loadingSearch = true;
      this.getModelAndPricesFromExtranet({
        model: modelCode
      });

      this.searchResult = [];
      this.clickedInside = false;
      this.loadingSearch = false;
      this.searchText = "";
      this.$emit('model-selected')
    },

    // Función para ocultar la lista
    hideList() {
      if (this.loadingSearch || this.clickedInside) return;
      this.showListActive = false;
    },

    // Función para mostrar la lista
    showList() {
      this.showListActive = true;
    },

    onClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.hideList();
      }
    },

    itemMouseDown() {
      this.clickedInside = true;
    },
  }
};
</script>

<style lang="scss">


.searchModelDialog {
  max-width: 505px;
  width: 100%;
  border-radius: 23px !important;
  height: fit-content;
  max-height: 600px;
  position: relative;
  transition: all 0.3s;


  .animated {
    .v-input__slot {
      animation: border-animation-primary 1s linear infinite;
      
    }
  }

  .v-input__slot {
    background: #f6f6f6;
    border: 3px solid #f6f6f6;
    div.v-input__prepend-inner i, input {
      color: black !important;
    }
  }

  &:hover {
    .v-input__slot {
      background: #ffffff;
    }
  }

  &.listActive {
    border-radius: 23px 23px 0px 0px;
  }

  .listSerachModel {
    max-width: 505px;
    min-width: 100%;
    max-height: 450px;
    overflow-y: auto;
    right: 0;
    width: auto;
    position: absolute;
    top: 100%;
    z-index: 13000;
    display: block;
    border: 1px solid var(--v-bannerBackground-base);

    .item-list {
      content-visibility: auto
    }
  }
}

@keyframes border-animation-primary {
  0%,
  100% {
    border-color: white;
  }
  50% {
    border-color: var(--v-colorBtn-base);
  }
}
</style>
